<template>
  <v-container>
    <v-card v-if="selectedBlog" :width="isMobile ? '90vw' : '50vw'" class="mx-auto">
      <v-btn v-if="selectedBlog" fab large color="blue lighten-2" @click="clearBlogChoice"
        style="left:-20px;top:-20px;z-index:2;position:absolute">
        <v-icon dark x-large id="close">mdi-close</v-icon>
      </v-btn>
      <!-- first-row  -->
      <v-row class="justify-center align-center">
        <v-col cols="12" class="pt-0">
          <v-img v-if="selectedBlog && selectedBlog.picture" :src="selectedBlog.picture" class="rounded-t-lg"
            max-height="300" position="center center" cover :alt="selectedBlog.title">
            <v-card-title class="blog-title white--text justify-center align-self-end"
              :class="isMobile ? 'text-h6' : 'text-h3'"
              :style="$vuetify.breakpoint.mdAndUp ? 'font-size:1.8rem;' : 'font-size:1rem;'">
              {{ selectedBlog.title ? selectedBlog.title : "" }}
            </v-card-title>
          </v-img>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col>
          <v-card-text class="ml-2 mt-2 pa-0 info-text">
            <div>
              Category:
              {{
    selectedBlog && selectedBlog.category && selectedBlog.category.name
      ? selectedBlog.category.name
      : ""
}}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-text class="blog-text pb-16 text-center" :class="isMobile ? 'px-4' : 'px-16'" :style="
  $vuetify.breakpoint.mdAndUp
    ? 'font-size:1.2rem;line-height:2rem;'
    : 'font-size:1rem;line-height:1.2rem;'
" v-html="selectedBlog && selectedBlog.body ? selectedBlog.body : '<div></div>'">
      </v-card-text>
    </v-card>
    <v-row justify="center" v-if="selectedBlog">
      <v-col align="center">
        <v-btn class="mx-auto" dark color="primary" @click="clearBlogChoice">
          <v-icon>mdi-arrow-left-thick</v-icon>
          Go Back
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Blogs",
  components: {},
  data: () => ({}),
  props: {
    selectedBlog: {
      type: [Array, Object]
    }
  },
  metaInfo() {
    return {
      title: `Blog - ${this.selectedBlog?.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `${this.selectedBlog?.intro_text}`
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${this.selectedBlog?.intro_text}`
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `GraceWay Blog - ${this.selectedBlog?.title}`
        },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://gracewayradio.com/blog/${this.selectedBlog?.slug}`
        }
      ]
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    clearBlogChoice() {
      this.$store.dispatch("blog/clearChosenBlog");
      // this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.blog-text {
  & ::v-deep {
    h2 {
      margin-bottom: 0.5em;
    }

    hr {
      margin-bottom: 1em;
    }

    blockquote {
      background: #f9f9f9;
      font-weight: bold;
      border-left: 0.8em solid darkgrey;
      margin: 1.6em 2em;
      padding: 1em 2em;
      quotes: "\201C" "\201D" "\2018" "\2019";

      &::before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
      }
    }

    p {
      font-family: "Raleway", sans-serif !important;
      font-size: 16px;
      text-indent: 15px !important;
      color: black !important;
    }

    span {
      font-family: "Raleway", sans-serif !important;
      font-size: 16px;
      text-indent: 10px !important;
    }
  }
}

#close {
  transition: transform 0.9s ease-in-out;
}

#close:hover {
  transform: rotate(360deg) scale(1.6);
}

.info-text {
  color: darkgray;
}

.first-row {
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.blog-title {
  text-shadow: 4px 2px 5px rgba(0, 21, 169, 0.59);
}

.blog-text {
  margin-bottom: 2rem;
}
</style>
